export enum JobType {
  idAml = 'id-aml',
  idVerification = 'id-verification',
  idReverification = 'id-reverification',
  idCrosscheck = 'id-crosscheck',
  idAamva = 'id-aamva',
  ecbsvSSN = 'ecbsv-ssn',
  privateSSN = 'private-ssn',
  dob = 'dob',
  selfieVerification = 'selfie-verification'
}
