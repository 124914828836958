import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { JobStatus } from '../utils';
import { AMLDropdowns } from './AML/AMLDropdowns';
import { CrossCheckDropdowns } from './CrossCheck/CrossCheckDropdowns';
import { DOBDropdowns } from './DOB/DOBDropdowns';
import { DeleteDropdown } from './Delete/DeleteDropdown';
import { IDVDropdowns } from './IDV/IDVDropdowns';
import { ECBSVDropdowns } from './SSN/ECBSV/ECBSVDropdowns';
import { PrivateSSNDropdowns } from './SSN/PrivateSSN/PrivateSSNDropdowns';
import { JobType } from 'components/account/jobs/job_type';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import {
  JobError,
  JobRequestInfo,
  JobRequestParameters,
  JobResult,
  JobUpdateReview
} from 'models/jobs/jobs';
import {
  ACCOUNT_ADMIN_PERMISSIONS,
  DOWNLOADER_PERMISSIONS,
  isAuthorized
} from 'security/authorization';
import { useGlobalState } from 'store/reducers/reducer';

interface JobResultDropdownsProps {
  readonly jobErrors: JobError[];
  readonly jobRequestInfo?: JobRequestInfo;
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobSid: string;
  readonly jobDebugResults?: string;
  readonly jobStatus: JobStatus;
  readonly jobResult?: JobResult;
  readonly jobType: JobType;
  readonly crossCheckResult?: CrossCheckResult;
  readonly jobReview: JobUpdateReview | null;
  readonly setJobReviewSuccess: (success: boolean | null) => void;
}

export const JobResultDropdowns: FC<JobResultDropdownsProps> = ({
  jobErrors,
  jobRequestInfo,
  jobRequestParams,
  jobSid,
  jobStatus,
  jobDebugResults,
  jobResult,
  jobType,
  crossCheckResult,
  jobReview,
  setJobReviewSuccess
}) => {
  const { user } = useGlobalState().state;
  const isAdminRoute = useLocation().pathname.includes('/admin');
  const filesDropdownVisible =
    !isAdminRoute && isAuthorized(user!, DOWNLOADER_PERMISSIONS);

  const deleteDropdownVisible =
    !isAdminRoute && isAuthorized(user!, ACCOUNT_ADMIN_PERMISSIONS);

  const getDropdowns = (type: JobType) => {
    switch (type) {
      case JobType.idVerification:
      case JobType.idReverification:
      case JobType.selfieVerification:
        return (
          <IDVDropdowns
            crossCheckResult={crossCheckResult}
            debugDropdownVisible={isAdminRoute}
            filesDropdownVisible={filesDropdownVisible}
            jobDebugResults={jobDebugResults}
            jobErrors={jobErrors}
            jobRequestInfo={jobRequestInfo}
            jobRequestParams={jobRequestParams}
            jobResult={jobResult}
            jobReview={jobReview}
            jobSid={jobSid}
            jobStatus={jobStatus}
            setJobReviewSuccess={setJobReviewSuccess}
          />
        );
      case JobType.ecbsvSSN:
        return <ECBSVDropdowns jobSid={jobSid} jobStatus={jobStatus} />;
      case JobType.privateSSN:
        return <PrivateSSNDropdowns jobSid={jobSid} jobStatus={jobStatus} />;
      case JobType.dob:
        return <DOBDropdowns jobSid={jobSid} jobStatus={jobStatus} />;
      case JobType.idAml:
        return (
          <AMLDropdowns
            isAMLOnly
            jobAMLResults={jobResult?.aml}
            jobErrors={jobErrors}
            jobSid={jobSid}
            jobStatus={jobStatus}
          />
        );
      case JobType.idCrosscheck:
        return (
          <CrossCheckDropdowns
            crossCheckResult={crossCheckResult}
            jobErrors={jobErrors}
            jobStatus={jobStatus}
          />
        );
      case JobType.idAamva:
        return null;
    }
  };

  return (
    <>
      {getDropdowns(jobType)}
      {deleteDropdownVisible && <DeleteDropdown />}
    </>
  );
};
