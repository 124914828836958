import { Box, Text, ThemeContext } from '@vouched-id/vault';
import React, { FC, useCallback, useContext, useState } from 'react';
import { DropdownItem } from '../DropdownItemsSection';
import { Tooltip } from '../Tooltip';
import { ReviewIcon } from './ReviewIcon';
import { JobUpdateReview } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';
import { updateReview } from 'store/sagas/sagas';

export type JobReviewSingleItem = {
  [key in keyof JobUpdateReview]: boolean | null;
};

interface ReviewTableProps {
  readonly dropdownItems: DropdownItem[];
  readonly jobReview: JobUpdateReview | null;
  readonly jobSid: string;
  readonly setJobReviewSuccess: (success: boolean | null) => void;
}

export const ReviewTable: FC<ReviewTableProps> = ({
  dropdownItems,
  jobReview,
  jobSid,
  setJobReviewSuccess
}) => {
  const { asyncDispatch } = useGlobalState();
  const { tokens } = useContext(ThemeContext).theme;
  const [reviewItems, setReviewItems] = useState<JobUpdateReview>(
    jobReview ?? {}
  );

  const handleReviewUpdate = useCallback(
    (item: JobReviewSingleItem) => {
      const updatedReviewItems = { ...reviewItems, ...item };
      setReviewItems(updatedReviewItems);
      if (Object.values(updatedReviewItems).every((value) => value === null)) {
        setJobReviewSuccess(null);
      } else {
        setJobReviewSuccess(!Object.values(updatedReviewItems).includes(false));
      }

      asyncDispatch(updateReview(jobSid, updatedReviewItems, false)).catch(
        console.error
      );
    },
    [reviewItems]
  );

  const tCellStyles = {
    border: '1px solid #dddddd',
    padding: tokens.spacing.small,
    verticalAlign: 'center'
  };

  const thStyles = {
    textAlign: 'left' as const,
    backgroundColor: tokens.colors.purple,
    color: tokens.colors.white
  };

  return (
    <table
      style={{
        borderCollapse: 'collapse',
        width: '100%',
        margin: '25px 0'
      }}
    >
      <tbody>
        <tr>
          <th
            style={{
              ...tCellStyles,
              ...thStyles
            }}
          >
            Field
          </th>
          <th
            style={{
              ...tCellStyles,
              ...thStyles
            }}
          >
            Vouched
          </th>
          <th
            style={{
              ...tCellStyles,
              ...thStyles
            }}
          >
            Review
          </th>
        </tr>
        {dropdownItems.map((item) => (
          <tr key={item.text}>
            <td style={tCellStyles}>
              <Box alignItems="center" display="flex">
                <Text
                  style={{
                    marginRight: '10px'
                  }}
                >
                  {item.text}
                </Text>
                {item.tooltip && <Tooltip>{item.tooltip}</Tooltip>}
              </Box>
            </td>
            <td style={tCellStyles}>
              <Box alignItems="center" display="flex">
                {item.statusIcon}
              </Box>
            </td>
            <td style={tCellStyles}>
              <Box alignItems="center" display="flex">
                <ReviewIcon
                  handleReviewUpdate={handleReviewUpdate}
                  label={item.reviewLabel!}
                  originalResultIcon={item.statusIcon}
                  reviewItems={reviewItems}
                />
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
