import { ThemeContext } from '@vouched-id/vault';
import React, { useContext } from 'react';
import { ApprovedIcon } from '../Icons/Approved';
import { NotApplicableIcon } from '../Icons/NotApplicable';
import { RejectedIcon } from '../Icons/Rejected';
import { WarningIcon } from '../Icons/Warning';
import { JobStatus } from '../utils';
import { DropdownItem } from './DropdownItemsSection';
import { JobUpdateReview } from 'models/jobs/jobs';

export const useTableStyles = (): {
  captionStyles: React.CSSProperties;
  tableStyles: React.CSSProperties;
  thStyles: React.CSSProperties;
  tdStyles: React.CSSProperties;
  trStyles: React.CSSProperties;
} => {
  const { tokens } = useContext(ThemeContext).theme;

  return {
    captionStyles: {
      padding: tokens.spacing.xsmall,
      fontWeight: tokens.text.weight.bold
    },
    tableStyles: {
      border: `1px solid ${tokens.colors.gray100}`,
      borderCollapse: 'collapse',
      width: '80%',
      margin: `${tokens.spacing.large} 0`
    },
    thStyles: {
      border: `1px solid ${tokens.colors.gray100}`,
      padding: tokens.spacing.xsmall,
      fontWeight: tokens.text.weight.bold,
      color: tokens.colors.white,
      backgroundColor: tokens.colors.purple
    },
    tdStyles: {
      border: `1px solid ${tokens.colors.gray100}`,
      padding: tokens.spacing.xsmall
    },
    trStyles: {
      backgroundColor: tokens.colors.gray50
    }
  };
};

export const dropdownWrapperStyle = {
  margin: '0 auto 0 0',
  width: ['100%', '70%']
};

export const DropdownStatusIcon: React.FC<{
  readonly jobStatus: JobStatus;
  readonly dropdownHasRejections: boolean;
  readonly dropdownHasWarnings: boolean;
}> = ({ jobStatus, dropdownHasRejections, dropdownHasWarnings }) => {
  if (jobStatus === 'approved' || jobStatus === 'rejected') {
    if (dropdownHasRejections) {
      return <RejectedIcon />;
    }

    if (dropdownHasWarnings) {
      return <WarningIcon />;
    }

    return <ApprovedIcon />;
  }

  return null;
};

interface DropdownItemWithoutIcon {
  text: string;
  hasErrorOrWarning: {
    hasError: boolean;
    hasWarning: boolean;
  };
  tooltip?: string;
  submittedData?: string;
  reviewLabel?: keyof JobUpdateReview;
}

export enum DropdownItemTypes {
  BooleanDropdownItem = 'BooleanDropdownItem',
  ConfidenceDropdownItem = 'ConfidenceDropdownItem'
}

export interface BooleanDropdownItem extends DropdownItemWithoutIcon {
  type: DropdownItemTypes.BooleanDropdownItem; // need to know type at runtime
  value?: boolean;
  available: boolean; // value is not undefined
}

export interface ConfidenceDropdownItem extends DropdownItemWithoutIcon {
  type: DropdownItemTypes.ConfidenceDropdownItem; // need to know type at runtime
  available: boolean; // confidence is not null | undefined
}

export const addIconsToDropdownItems = (
  dropdownItems: (BooleanDropdownItem | ConfidenceDropdownItem)[]
): {
  dropdownHasRejections: boolean;
  dropdownHasWarnings: boolean;
  dropdownItems: DropdownItem[];
} => {
  let dropdownHasRejections = false;
  let dropdownHasWarnings = false;

  const dropdownItemsWithIcons = dropdownItems.map((item) => {
    const { hasError, hasWarning } = item.hasErrorOrWarning;
    dropdownHasRejections ||= hasError;
    dropdownHasWarnings ||= hasWarning;

    if (hasError) {
      return {
        ...item,
        statusIcon: <RejectedIcon />
      };
    }

    if (hasWarning) {
      return {
        ...item,
        statusIcon: <WarningIcon />
      };
    }

    if (item.type === DropdownItemTypes.BooleanDropdownItem) {
      dropdownHasRejections ||= item.value === false;
      return {
        ...item,
        statusIcon: <BooleanItemIcon value={item.value} />
      };
    }

    return {
      ...item,
      statusIcon: item.available ? <ApprovedIcon /> : <NotApplicableIcon />
    };
  });

  return {
    dropdownItems: dropdownItemsWithIcons,
    dropdownHasRejections,
    dropdownHasWarnings
  };
};

const BooleanItemIcon: React.FC<{ readonly value: boolean | undefined }> = ({
  value
}) => {
  if (value === true) {
    return <ApprovedIcon />;
  }

  if (value === false) {
    return <RejectedIcon />;
  }

  return <NotApplicableIcon />;
};

export const readableBoolean = (bool: boolean | undefined): string => {
  if (bool === undefined) {
    return '';
  }

  return bool ? 'True' : 'False';
};

export const readableString = (str: string | null | undefined): string => {
  if (str === null || str === undefined) {
    return '';
  }

  return str;
};

export const readableNumber = (num: number | null | undefined): string => {
  if (num === null || num === undefined) {
    return '';
  }

  return String(num);
};
