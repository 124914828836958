import { Box } from '@vouched-id/vault';
import React, { FC } from 'react';
import ReactJson from 'react-json-view';
import { Dropdown } from '../Dropdown';
import { copyHandler } from 'components/account/jobs/job-modal/job-modal-tabs/utils';

interface DebugDropdownProps {
  readonly jobDebugResults?: string;
}

export const DebugDropdowns: FC<DebugDropdownProps> = ({ jobDebugResults }) => {
  let parsedDebugResults: object;
  try {
    parsedDebugResults = jobDebugResults
      ? (JSON.parse(jobDebugResults) as object)
      : { results: 'No debug results available' };
  } catch {
    parsedDebugResults = { results: 'Unable to parse debug results' };
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const templateId = (parsedDebugResults as any).results[0]?.result?.id
    ?.docengine?.google?.detect?.id as string | undefined;

  return (
    <Dropdown title="Debug">
      <Box>
        {templateId && (
          <Box marginY="spacing.default">
            <a
              className="job-modal__debug__template-link"
              href={`/admin/templates/${templateId}`}
              rel="noreferrer"
              target="_blank"
            >
              Link to the template used for this ID
            </a>
          </Box>
        )}
        <ReactJson enableClipboard={copyHandler} src={parsedDebugResults} />
      </Box>
    </Dropdown>
  );
};
