import React, { FC } from 'react';
import { SingleSpanTable } from '../../SingleSpanTable';
import { CvsRequestItem } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/ssn_model';
import { getDOB } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/utils';

interface ECBSVRequestDataTableProps {
  readonly requestData: CvsRequestItem;
}

export const ECBSVRequestDataTable: FC<ECBSVRequestDataTableProps> = ({
  requestData
}) => {
  let signatureType: string | undefined;
  switch (requestData.additionalParams?.signatureType) {
    case 'E':
      signatureType = 'Electronic Signature';
      break;
    case 'W':
      signatureType = 'Wet Signature';
      break;
    case undefined:
  }

  return (
    <SingleSpanTable
      items={[
        {
          label: 'First Name',
          value: requestData.firstName
        },
        {
          label: 'Middle Name',
          value: requestData.middleName
        },
        {
          label: 'Last Name',
          value: requestData.lastName
        },
        {
          label: 'Date of Birth',
          value: getDOB(requestData.dateOfBirth)
        },
        {
          label: 'Signature Type',
          value: signatureType
        }
      ]}
      numColumns={2}
    />
  );
};
