import React, { useContext } from 'react';
import { MessageProps } from 'components/message';
import {
  CompanyAccount,
  CompanyAccountGroup
} from 'models/company-account/company-account.model';
import { User } from 'models/user/user.model';
import {
  Action,
  LOGOUT,
  SET_2FA_DATA,
  SET_ACCOUNT,
  SET_ACCOUNT_GROUPS,
  SET_ACCOUNTS,
  SET_AUTHDATA,
  SET_CURRENT_CUSTOMER,
  SET_CURRENT_SUBSCRIPTION,
  SET_INVOICES,
  SET_MESSAGES,
  SET_PAYMENT_METHODS,
  SET_SANDBOX_TOGGLED,
  SET_SEARCHED_USERS,
  SET_USER,
  SET_USER_ACCOUNT_GROUPS
} from 'store/actions/actions';
import { CustomerInfo, Invoice, PaymentMethod } from 'store/sagas/sagas';

export interface Subscription {
  status: string;
  collection_method: string;
}

export interface StoreState {
  modalType: string;
  user: User | null;
  account: CompanyAccount | null;
  accountGroups?: CompanyAccountGroup[] | null;
  userAccountGroups?: CompanyAccountGroup[] | null;
  accounts?: CompanyAccount[] | null;
  accessToken: string;
  source: string;
  messages: MessageProps[];
  otpToken: string;
  otpImage?: string;
  currentSubscription?: Subscription;
  invoices: Invoice[] | null;
  paymentMethods: PaymentMethod[];
  resToken: string;
  customer?: CustomerInfo;
  sandboxToggled: boolean;
  accountSettings: Record<string, unknown>;
  searchedUsers: User[];
}

export interface StoreProps {
  state: StoreState;
  dispatch: (event: Action) => void;
  asyncDispatch: <T>(
    action: T | ((props: StoreProps) => Promise<T>)
  ) => Promise<T>;
}

const initState = () => {
  const token = localStorage.getItem('accessToken');
  const sandboxToggled = localStorage.getItem('sandboxToggled');
  const initialData: StoreState = {
    accessToken: '',
    account: null,
    accountGroups: null,
    accountSettings: {},
    invoices: null,
    messages: [],
    modalType: '',
    otpImage: '',
    otpToken: '',
    paymentMethods: [],
    user: null,
    resToken: '',
    sandboxToggled: sandboxToggled === 'true',
    searchedUsers: [],
    source: ''
  };

  return {
    ...initialData,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    accessToken: token || ''
  };
};

export const initialState: StoreState = initState();

export function rootReducer(
  state: StoreState | undefined,
  action: Action
): StoreState {
  state ??= initialState;
  switch (action.type) {
    case SET_AUTHDATA:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        account: action.payload.account,
        resToken: action.payload.resToken,
        user: action.payload.user
      };
    case LOGOUT:
      return {
        ...state,
        accessToken: '',
        account: action.payload.account,
        sandboxToggled: false,
        user: action.payload.user
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload.user
      };
    case SET_USER_ACCOUNT_GROUPS:
      return {
        ...state,
        userAccountGroups: action.payload.userAccountGroups
      };
    case SET_ACCOUNT_GROUPS:
      return {
        ...state,
        accountGroups: action.payload.accountGroups
      };
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload.accounts
      };
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload.account
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };
    case SET_2FA_DATA:
      return {
        ...state,
        otpImage: action.payload.otpImage,
        otpToken: action.payload.otpToken,
        resToken: action.payload.resToken
      };
    case SET_CURRENT_CUSTOMER:
      return {
        ...state,
        customer: action.payload.currentCustomer
      };
    case SET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload.currentSubscription
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload.invoices
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods
      };
    case SET_SANDBOX_TOGGLED:
      return {
        ...state,
        sandboxToggled: action.payload.sandboxToggled
      };
    case SET_SEARCHED_USERS:
      return {
        ...state,
        searchedUsers: action.payload.searchedUsers
      };
  }
}

const StoreContext = React.createContext({} as StoreProps);

export const StoreProvider = StoreContext.Provider;

export const useGlobalState = (): StoreProps => useContext(StoreContext);
