import { Checkbox } from '@vouched-id/vault';
import React, { FC, useCallback, useState } from 'react';
import { useGlobalState } from 'store/reducers/reducer';
import { updateReviewStatus } from 'store/sagas/sagas';

interface ReviewedCheckboxProps {
  readonly sid: string;
  readonly jobAccountReviewed: boolean;
}

export const ReviewedCheckbox: FC<ReviewedCheckboxProps> = ({
  sid,
  jobAccountReviewed
}) => {
  const { asyncDispatch } = useGlobalState();
  const [checked, setChecked] = useState(jobAccountReviewed);

  const handleCheck = useCallback(() => {
    const newChecked = !checked;
    setChecked(newChecked);
    asyncDispatch(
      updateReviewStatus({ id: sid, value: newChecked }, false)
    ).catch(console.error);
  }, [checked, asyncDispatch]);

  return <Checkbox checked={checked} onClick={handleCheck} variation="small" />;
};
