import { Tooltip } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { toName } from '../../utils';
import { JobType } from 'components/account/jobs/job_type';
import { InfoIcon } from 'components/reusable/info-icon/info-icon';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { Job, JobResult } from 'models/jobs/jobs';
import { roundNumber } from 'utils/ui.utils';

interface RenderItem {
  itemName: string;
  name: React.ReactNode | (() => React.ReactNode);
  customRenderValue: () => ReactElement;
}

export const Scores: FC<{
  readonly job: Job;
  readonly result: JobResult;
}> = ({ result, job }) => {
  const parameters = job.request.parameters!;
  const { dob, scannedBarcode } = parameters;
  const name = toName(parameters);
  let items: RenderItem[] = [
    {
      itemName: 'ID',
      name: (
        <>
          {'ID '}
          <Tooltip
            enterTouchDelay={1}
            title="The overall identity score. This value is calculated once the job is completed"
          >
            <InfoIcon />
          </Tooltip>
        </>
      ),
      customRenderValue: () => {
        const idScore = roundNumber(result.confidences?.id);

        return (
          <Text
            htmlColor={idScore && idScore >= 0.9 ? 'green' : 'red'}
            value={idScore}
          />
        );
      }
    },
    {
      itemName: 'Selfie',
      name: (
        <>
          {'Selfie '}
          <Tooltip enterTouchDelay={1} title="Did Vouched recognize a face?">
            <InfoIcon />
          </Tooltip>
        </>
      ),
      customRenderValue: () => {
        const selfieScore = roundNumber(result.confidences?.selfie);
        return (
          <Text
            htmlColor={selfieScore && selfieScore >= 0.9 ? 'green' : 'red'}
            value={selfieScore}
          />
        );
      }
    },
    {
      itemName: 'Face Match',
      name: (
        <>
          {'Face Match '}
          <Tooltip
            enterTouchDelay={1}
            title="Do the faces match on the two images?"
          >
            <InfoIcon />
          </Tooltip>
        </>
      ),
      customRenderValue: () => {
        const faceMatchScore = roundNumber(result.confidences?.faceMatch);
        return (
          <Text
            htmlColor={
              faceMatchScore && faceMatchScore >= 0.9 ? 'green' : 'red'
            }
            value={faceMatchScore}
          />
        );
      }
    },
    {
      itemName: 'Expired',
      name: (
        <>
          {'Expired '}
          <Tooltip enterTouchDelay={1} title="Is the ID expired?">
            <InfoIcon />
          </Tooltip>
        </>
      ),
      customRenderValue: () => {
        const expiredIdScore = roundNumber(result.confidences?.idExpired);
        return (
          <Text
            htmlColor={expiredIdScore === 1 ? 'green' : 'red'}
            value={
              job.request.type === JobType.selfieVerification
                ? undefined
                : expiredIdScore === 1
                  ? 'No'
                  : 'Yes'
            }
          />
        );
      }
    },
    name
      ? {
          itemName: 'Name Match',
          name: (
            <>
              {'Name Match '}
              <Tooltip
                enterTouchDelay={1}
                title="Does the provided name match the name from the ID?"
              >
                <InfoIcon />
              </Tooltip>
            </>
          ),
          customRenderValue: () => {
            const nameMatchScore = roundNumber(result.confidences?.nameMatch);
            return (
              <Text
                htmlColor={
                  nameMatchScore && nameMatchScore >= 0.9 ? 'green' : 'red'
                }
                value={nameMatchScore}
              />
            );
          }
        }
      : null,
    dob
      ? {
          itemName: 'Birth Date Match',
          name: (
            <>
              {'Birth Date Match '}
              <Tooltip
                enterTouchDelay={1}
                title="Does the provided date of birth match the date of birth from the ID?"
              >
                <InfoIcon />
              </Tooltip>
            </>
          ),
          customRenderValue: () => {
            const birthDateMatchScore = roundNumber(
              result.confidences?.birthDateMatch
            );

            const isBirthDateValid =
              birthDateMatchScore && birthDateMatchScore >= 0.9;

            return (
              <Text
                htmlColor={isBirthDateValid ? 'green' : 'red'}
                value={
                  job.request.type === JobType.selfieVerification
                    ? undefined
                    : isBirthDateValid
                      ? 'Yes'
                      : 'No'
                }
              />
            );
          }
        }
      : null,
    scannedBarcode
      ? {
          itemName: 'Barcode Match',
          name: (
            <>
              {'Barcode Match '}
              <Tooltip
                enterTouchDelay={1}
                title="Does the information on the ID card match the information encoded in the barcode?"
              >
                <InfoIcon />
              </Tooltip>
            </>
          ),
          customRenderValue: () => {
            let barcodeMatchScore: number | string | null = roundNumber(
              result.confidences?.barcodeMatch
            );

            if (!job.result?.clientOutput?.includeBarcode) {
              barcodeMatchScore = 'N/A';
            }

            return (
              <Text
                htmlColor={
                  barcodeMatchScore && typeof barcodeMatchScore === 'string'
                    ? 'black'
                    : (barcodeMatchScore as number) >= 0.9
                      ? 'green'
                      : 'red'
                }
                value={barcodeMatchScore}
              />
            );
          }
        }
      : null
  ].filter(Boolean) as RenderItem[];

  const AUTH_ID_COLS = new Set(['face match']);

  items = items.filter((item) => {
    if (item.itemName && job.request.type === JobType.idReverification) {
      return AUTH_ID_COLS.has(item.itemName.toLowerCase());
    }

    return true;
  });

  return (
    <List
      columns={[
        {
          name: 'name',
          customRenderValue: ({ name: displayName }) =>
            typeof displayName === 'function'
              ? (displayName as () => React.ReactNode)()
              : displayName,
          xs: 2
        },
        {
          name: 'value',
          xs: 10,
          customRenderValue: (item) => item.customRenderValue()
        }
      ]}
      items={items}
      page={1}
      showHeaders={false}
    />
  );
};
