import React, { FC } from 'react';
import { MultiSpanTable, MultiSpanTableEntry } from '../MultiSpanTable';
import { AMLSanctionDetails } from './AMLCompletedResult';

interface AMLDetailsTablesProps {
  readonly sanctions: AMLSanctionDetails[];
}

export const AMLDetailsTables: FC<AMLDetailsTablesProps> = ({ sanctions }) => {
  const entries: MultiSpanTableEntry[] = sanctions.map((sanction) => {
    return {
      title: sanction.type.toUpperCase(),
      items: [
        { label: 'Listed Name', value: sanction.name.toUpperCase() },
        { label: 'Listed Url', value: sanction.url },
        { label: 'Listed', value: sanction.dates.start },
        { label: 'Listing Ended', value: sanction.dates.end }
      ]
    };
  });

  return <MultiSpanTable entries={entries} header="Matched Types" />;
};
