import { Box, Heading, Icons, ThemeContext } from '@vouched-id/vault';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Image } from '../jobs/image-slider/smart-image';
import { jobHasWarnings } from '../jobs/job-modal/job-modal-tabs/utils';
import { JobType } from '../jobs/job_type';
import { Card } from './Card';
import { CameraIcon } from './Icons/Camera';
import { JobImages } from './JobImages';
import { JobStatusBar } from './JobStatusBar';
import { JobResultDropdowns } from './dropdowns/JobResultDropdowns';
import { calculateJobStatus, FontWeights, JobPageParams } from './utils';
import { Job } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';
import { loadJob } from 'store/sagas/sagas';
import { createImages } from 'utils/jobs';

export const JobPage: FC = () => {
  const { asyncDispatch, state } = useGlobalState();
  const { id: jobSid } = useParams<JobPageParams>();
  const [job, setJob] = useState<Job | null>(null);
  const [userImages, setUserImages] = useState<Image[]>([]);
  const [frontIdImages, setFrontIdImages] = useState<Image[]>([]);
  const [backIdImages, setBackIdImages] = useState<Image[]>([]);
  const [showImages, setShowImages] = useState(false);
  const [jobCanHaveImages, setJobCanHaveImages] = useState(false);
  const [jobReviewSuccess, setJobReviewSuccess] = useState<boolean | null>(
    null
  );

  const { tokens } = useContext(ThemeContext).theme;

  useEffect(() => {
    asyncDispatch(loadJob(jobSid, state.user?.isAdmin, false)).then(
      setJob,
      console.error
    );
  }, [jobSid]);

  useEffect(() => {
    if (!job || job.status === 'removed') return;

    if (
      job.request.type === JobType.idVerification ||
      job.request.type === JobType.idReverification
    ) {
      setJobCanHaveImages(true);
      const { parameters } = job.request;

      if (parameters) {
        setUserImages(
          createImages(parameters, [
            'userDetect',
            'user',
            'userDistance',
            'source'
          ])
        );

        setFrontIdImages(
          createImages(parameters, ['idDetect', 'id', 'idDistance', 'dest'])
        );

        setBackIdImages(createImages(parameters, ['backId']));
        setJobReviewSuccess(job.reviewSuccess ?? null);
      }
    }
  }, [job]);

  const handleShowImages = useCallback(() => {
    setShowImages((v) => !v);
  }, []);

  if (!job) {
    return <Icons.Loading />;
  }

  const jobStatus = calculateJobStatus(job.status, job.result?.success);

  return (
    <Box
      data-test="job-v2-view"
      display={['block']}
      paddingX="spacing.xlarge"
      paddingY="spacing.large"
      style={{ boxSizing: 'border-box' }}
      tag="main"
      width="100%"
    >
      <Box marginBottom="spacing.large">
        <Heading
          style={{
            fontWeight: FontWeights.SemiBold
          }}
          tag="h1"
          variation="h3"
        >
          Jobs
        </Heading>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        marginBottom="spacing.large"
        style={{
          gap: '15px'
        }}
      >
        <Box
          backgroundColor="colors.white"
          flex={['1 0 70%', '0 0 70%']}
          paddingX="spacing.small"
          paddingY="spacing.large"
          style={{
            borderRadius: '10px',
            boxShadow: tokens.shadows.hover,
            boxSizing: 'border-box'
          }}
        >
          <JobStatusBar
            hasWarnings={jobHasWarnings(job.errors)}
            isAMLOnly={job.request.type === JobType.idAml}
            jobAccountReviewed={!!job.accountReviewed}
            jobReviewSuccess={jobReviewSuccess}
            status={jobStatus}
            submittedAt={job.submitted}
            updatedAt={job.updatedAt}
          />
        </Box>
        {jobCanHaveImages && (
          <Card onClick={handleShowImages} title="Images">
            <CameraIcon />
          </Card>
        )}
      </Box>
      {showImages && (
        <JobImages
          backIdImages={backIdImages}
          frontIdImages={frontIdImages}
          isReverification={job.request.type === JobType.idReverification}
          userImages={userImages}
        />
      )}
      {jobStatus !== 'removed' && (
        <JobResultDropdowns
          crossCheckResult={job.result?.crosscheck}
          jobDebugResults={job.debugResults}
          jobErrors={job.errors}
          jobRequestInfo={job.request.requestInfo}
          jobRequestParams={job.request.parameters}
          jobResult={job.result}
          jobReview={job.review ?? null}
          jobSid={job.id}
          jobStatus={jobStatus}
          jobType={job.request.type}
          setJobReviewSuccess={setJobReviewSuccess}
        />
      )}
    </Box>
  );
};
