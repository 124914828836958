import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import CreditCard from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/Delete';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { ReusableSelect as Select } from 'components/reusable/select';
import { SelectEvent } from 'components/reusable/select/select';
import {
  setCurrentCustomer,
  setCurrentSubscription,
  setPaymentMethods
} from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import {
  addPaymentMethod,
  deletePaymentMethod,
  getCurrentCustomer,
  getCurrentSubscriptionObject,
  getPaymentMethods,
  PaymentMethod,
  setPaymentMethodDefault
} from 'store/sagas/sagas';

import './billing-payments.css';

export const PaymentMethods: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setIsLoading(true);
    asyncDispatch(getPaymentMethods())
      .then((data) => {
        dispatch(setPaymentMethods(data));
        return data;
      })
      .then(async (paymentMethods) => {
        return asyncDispatch(getCurrentCustomer()).then((customer) => {
          dispatch(setCurrentCustomer(customer));
          for (const pm of paymentMethods) {
            if (pm.id === customer.invoice_settings.default_payment_method) {
              setDefaultCard({
                id: pm.id,
                name: pm.id
              });
            }
          }
        });
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });

    asyncDispatch(getCurrentSubscriptionObject())
      .then((data) => {
        dispatch(setCurrentSubscription(data));
      })
      .catch(console.error);
  }, []);

  const [defaultCard, setDefaultCard] = useState({ id: '', name: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteMethod = (paymentMethod: PaymentMethod) => {
    if (!elements || !stripe) {
      return;
    }

    setIsLoading(true);
    asyncDispatch(deletePaymentMethod(paymentMethod.id))
      .then(async () => {
        return asyncDispatch(getPaymentMethods());
      })
      .then((data) => {
        dispatch(setPaymentMethods(data));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleDefaultChange = (event: SelectEvent<string>) => {
    asyncDispatch(setPaymentMethodDefault(event.target.value))
      .then(async () => {
        return asyncDispatch(getPaymentMethods())
          .then((data) => {
            dispatch(setPaymentMethods(data));
          })
          .then(async () => {
            return asyncDispatch(getCurrentCustomer());
          })
          .then((customer) => {
            dispatch(setCurrentCustomer(customer));
            for (const pm of state.paymentMethods) {
              if (pm.id === customer.invoice_settings.default_payment_method) {
                setDefaultCard({
                  id: pm.id,
                  name: pm.id
                });
              }
            }
          });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleSubmit = async (event: React.UIEvent) => {
    event.preventDefault();

    if (!elements || !stripe) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    const paymentMethodAttachData = {
      id: paymentMethod?.id
    };

    if (error) {
      console.error(error);
    }

    asyncDispatch(addPaymentMethod(paymentMethodAttachData))
      .then(async () => {
        return asyncDispatch(getPaymentMethods());
      })
      .then((data) => {
        dispatch(setPaymentMethods(data));
        setIsLoading(false);
      })
      .catch((error_) => {
        console.error(error_);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div>
        <h1>Payment Methods</h1>
        <div>
          {isLoading ? (
            <CircularProgress size="1.2rem" style={{ color: '#290e88' }} />
          ) : state.paymentMethods.length > 0 ? (
            <div>
              <Grid item md={6} xs={12}>
                <div>
                  <List>
                    {state.paymentMethods.map((paymentMethod) => {
                      return (
                        <div key={paymentMethod.id}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <CreditCard />
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              primary={`Card ending in ${paymentMethod.card.last4}`}
                              secondary={
                                state.customer?.invoice_settings
                                  .default_payment_method === paymentMethod.id
                                  ? 'Default'
                                  : null
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                aria-label="delete"
                                edge="end"
                                onClick={() => {
                                  handleDeleteMethod(paymentMethod);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      );
                    })}
                  </List>
                </div>
              </Grid>
              <h2 style={{ margin: '4vh 0 3vh 0' }}>
                Set Default Payment Method
              </h2>
              <Grid item xs={3}>
                <Select
                  className="select-group u-margin-top-large"
                  items={state.paymentMethods.map((pm) => {
                    return {
                      id: pm.id,
                      name: `Card ending in ${pm.card.last4}`
                    };
                  })}
                  name="defaultCard"
                  onChange={handleDefaultChange}
                  value={defaultCard.name}
                />
              </Grid>
            </div>
          ) : (
            <div>
              <p>
                There are no payment methods currently associated with your
                account. Without a payment method, invoices will be generated
                and sent to your account's email for you to manually pay
              </p>
            </div>
          )}

          <div>
            <h2 style={{ margin: '4vh 0 3vh 0' }}>Add New Payment Method</h2>
            <form>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4'
                      }
                    },
                    invalid: {
                      color: '#9e2146'
                    }
                  }
                }}
              />
              <div className="save-card-button">
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Button onClick={handleSubmit}>Save Card</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
