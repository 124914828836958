import { Text } from '@vouched-id/vault';
import React, { FC } from 'react';
import { Dropdown } from '../Dropdown';
import { SingleSpanTable } from '../SingleSpanTable';

interface ScoresDropdownProps {
  readonly id?: number;
  readonly selfie?: number;
  readonly faceMatch?: number;
  readonly expired?: number;
  readonly barcodeMatch?: number;
  readonly nameMatch?: number;
  readonly birthDateMatch?: number;
}

export const ScoresDropdown: FC<ScoresDropdownProps> = ({
  id,
  selfie,
  faceMatch,
  expired,
  barcodeMatch,
  nameMatch,
  birthDateMatch
}) => {
  return (
    <Dropdown title="Scores">
      <Text>Numerical confidence scores of checks performed for this job.</Text>
      <SingleSpanTable
        items={[
          {
            label: 'ID',
            value: id,
            ...(id !== undefined && { valueColor: id >= 0.9 ? 'green' : 'red' })
          },
          {
            label: 'Selfie',
            value: selfie,
            ...(selfie !== undefined && {
              valueColor: selfie >= 0.9 ? 'green' : 'red'
            })
          },
          {
            label: 'Face Match',
            value: faceMatch,
            ...(faceMatch !== undefined && {
              valueColor: faceMatch >= 0.9 ? 'green' : 'red'
            })
          },
          {
            label: 'Expired',
            value: expired,
            ...(expired !== undefined && {
              valueColor: expired === 1 ? 'green' : 'red'
            })
          },
          {
            label: 'Barcode Match',
            value: barcodeMatch,
            ...(barcodeMatch !== undefined && {
              valueColor: barcodeMatch >= 0.9 ? 'green' : 'red'
            })
          },
          {
            label: 'Name Match',
            value: nameMatch,
            ...(nameMatch !== undefined && {
              valueColor: nameMatch >= 0.9 ? 'green' : 'red'
            })
          },
          {
            label: 'Birth Date Match',
            value: birthDateMatch,
            ...(birthDateMatch !== undefined && {
              valueColor: birthDateMatch >= 0.9 ? 'green' : 'red'
            })
          }
        ]}
        numColumns={2}
      />
    </Dropdown>
  );
};
