import { Box, Text } from '@vouched-id/vault';
import React, { FC, useMemo } from 'react';
import { Dropdown } from '../../Dropdown';
import { DropdownItemsSection } from '../../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../../utils';
import { SSNCaption } from '../SSNCaption';
import { ECBSVRequestDataTable } from './ECBSVRequestDataTable';
import { CvsRequestItem } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/ssn_model';
import { JobType } from 'components/account/jobs/job_type';
import { JobStatus } from 'components/account/jobs-v2/utils';

interface ECBSVCompletedResultProps {
  readonly jobStatus: JobStatus;
  readonly requestData?: CvsRequestItem;
  readonly deathIndicator: string;
  readonly verified: boolean;
}

export const ECBSVCompletedResult: FC<ECBSVCompletedResultProps> = ({
  jobStatus,
  requestData,
  deathIndicator,
  verified
}) => {
  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: BooleanDropdownItem[] = [
        {
          text: 'SSN Match',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: verified,
          available: true,
          hasErrorOrWarning: {
            hasError: !verified,
            hasWarning: false
          }
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [verified]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="SSN"
    >
      <Box>
        <SSNCaption type={JobType.ecbsvSSN} />
        <DropdownItemsSection dropdownItems={dropdownItems} />
        <Box display="flex" style={{ gap: '15px' }}>
          <Text>Death Indicator:</Text>
          <Text style={{ fontWeight: '600' }}>{deathIndicator}</Text>
        </Box>
        <Box display="flex" style={{ gap: '15px' }}>
          <Text>Source:</Text>
          <Text style={{ fontWeight: '600' }}>eCBSV</Text>
        </Box>
        {requestData && <ECBSVRequestDataTable requestData={requestData} />}
      </Box>
    </Dropdown>
  );
};
