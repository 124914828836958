import { Box, Text } from '@vouched-id/vault';
import React, { FC, useMemo } from 'react';
import { Dropdown } from '../../Dropdown';
import { DropdownItemsSection } from '../../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../../utils';
import { SSNCaption } from '../SSNCaption';
import { PrivateSSNRequestDataTable } from './PrivateSSNRequestDataTable';
import { PrivateSSNRequestData } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/private_ssn/private_request_data_summary';
import { JobType } from 'components/account/jobs/job_type';
import { JobStatus } from 'components/account/jobs-v2/utils';

interface PrivateSSNCompletedResultProps {
  readonly jobStatus: JobStatus;
  readonly requestData?: PrivateSSNRequestData;
  readonly ssnIsMatch: boolean;
}

export const PrivateSSNCompletedResult: FC<PrivateSSNCompletedResultProps> = ({
  jobStatus,
  requestData,
  ssnIsMatch
}) => {
  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: BooleanDropdownItem[] = [
        {
          text: 'SSN Match',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: ssnIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: !ssnIsMatch,
            hasWarning: false
          }
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [ssnIsMatch]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="SSN"
    >
      <Box>
        <SSNCaption type={JobType.privateSSN} />
        <DropdownItemsSection dropdownItems={dropdownItems} />
        <Box display="flex" style={{ gap: '15px' }}>
          <Text>Source:</Text>
          <Text style={{ fontWeight: '600' }}>Private</Text>
        </Box>
        {requestData && (
          <PrivateSSNRequestDataTable requestData={requestData} />
        )}
      </Box>
    </Dropdown>
  );
};
