import { Box, ThemeContext } from '@vouched-id/vault';
import React, { FC, ReactElement, useContext } from 'react';
import { useLocation } from 'react-router';
import { ApprovedIcon } from '../../Icons/Approved';
import { RejectedIcon } from '../../Icons/Rejected';
import { JobReviewSingleItem } from './ReviewTable';
import { JobUpdateReview } from 'models/jobs/jobs';

const reviewIconStyle = { cursor: 'pointer' };

interface ReviewIconProps {
  readonly label: keyof JobUpdateReview;
  readonly originalResultIcon: ReactElement;
  readonly reviewItems: JobUpdateReview;
  readonly handleReviewUpdate: (item: JobReviewSingleItem) => void;
}

export const ReviewIcon: FC<ReviewIconProps> = ({
  label,
  originalResultIcon,
  reviewItems,
  handleReviewUpdate
}) => {
  const isAdminRoute = useLocation().pathname.includes('/admin');
  const { tokens } = useContext(ThemeContext).theme;

  if (typeof reviewItems[label] === 'boolean') {
    return (
      <Box
        onClick={
          isAdminRoute
            ? undefined
            : () => {
                handleReviewUpdate({ [label]: null });
              }
        }
        style={isAdminRoute ? undefined : reviewIconStyle}
      >
        {reviewItems[label] ? <ApprovedIcon /> : <RejectedIcon />}
      </Box>
    );
  }

  if (originalResultIcon.type === ApprovedIcon) {
    return (
      <Box
        onClick={
          isAdminRoute
            ? undefined
            : () => {
                handleReviewUpdate({ [label]: false });
              }
        }
        style={isAdminRoute ? undefined : reviewIconStyle}
      >
        <RejectedIcon fill={tokens.colors.gray300} />
      </Box>
    );
  }

  return (
    <Box
      onClick={
        isAdminRoute
          ? undefined
          : () => {
              handleReviewUpdate({ [label]: true });
            }
      }
      style={isAdminRoute ? undefined : reviewIconStyle}
    >
      <ApprovedIcon fill={tokens.colors.gray300} />
    </Box>
  );
};
