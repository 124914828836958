import React, { FC } from 'react';
import { JobStatus } from '../../utils';
import { AMLDropdowns } from '../AML/AMLDropdowns';
import { CrossCheckDropdowns } from '../CrossCheck/CrossCheckDropdowns';
import { DLVDropdown } from '../DLV/DLVDropdown';
import { DebugDropdowns } from '../Debug/DebugDropdowns';
import { FilesDropdown } from '../Files/FilesDropdown';
import { IdDocument } from '../IdDocument/IdDocument';
import { LocationDropdowns } from '../Location/LocationDropdowns';
import { ReviewDropdowns } from '../Review/ReviewDropdowns';
import { ScoresDropdown } from '../Scores/ScoresDropdown';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import {
  JobError,
  JobRequestInfo,
  JobRequestParameters,
  JobResult,
  JobUpdateReview
} from 'models/jobs/jobs';
import { roundNumber } from 'utils/ui.utils';

interface IDVDropdownsProps {
  readonly jobErrors: JobError[];
  readonly jobRequestInfo?: JobRequestInfo;
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
  readonly jobResult?: JobResult;
  readonly jobDebugResults?: string;
  readonly crossCheckResult?: CrossCheckResult;
  readonly debugDropdownVisible: boolean;
  readonly filesDropdownVisible: boolean;
  readonly jobReview: JobUpdateReview | null;
  readonly setJobReviewSuccess: (success: boolean | null) => void;
}

export const IDVDropdowns: FC<IDVDropdownsProps> = ({
  jobErrors,
  jobRequestInfo,
  jobRequestParams,
  jobSid,
  jobStatus,
  jobResult,
  jobDebugResults,
  crossCheckResult,
  debugDropdownVisible,
  filesDropdownVisible,
  jobReview,
  setJobReviewSuccess
}) => {
  const idAddress = jobResult?.idAddress;
  const ipAddress = jobResult?.ipAddress;
  const confidences = jobResult?.confidences;

  return (
    <>
      {!!jobResult && (
        <IdDocument
          jobErrors={jobErrors}
          jobRequestInfo={jobRequestInfo}
          jobRequestParams={jobRequestParams}
          jobResult={jobResult}
          jobStatus={jobStatus}
        />
      )}
      {jobResult?.featuresEnabled?.crosscheckEnabled && (
        <CrossCheckDropdowns
          crossCheckResult={crossCheckResult}
          jobErrors={jobErrors}
          jobStatus={jobStatus}
        />
      )}
      {jobResult?.dlv?.dlvEnabled && (
        <DLVDropdown
          dlvJobResult={jobResult.dlv}
          dlvSubmittedData={{
            id: jobResult.id,
            state: jobResult.state,
            lastName: jobResult.lastName,
            birthDate: jobResult.birthDate,
            expirationDate: jobResult.expireDate
          }}
          jobStatus={jobStatus}
        />
      )}
      {jobResult?.aml && (
        <AMLDropdowns
          isAMLOnly={false}
          jobAMLResults={jobResult.aml}
          jobErrors={jobErrors}
          jobSid={jobSid}
          jobStatus={jobStatus}
        />
      )}
      {(idAddress ?? ipAddress) && (
        <LocationDropdowns
          crossCheckIpDistance={
            typeof crossCheckResult?.ip?.distance === 'number'
              ? `${crossCheckResult.ip.distance}`
              : ''
          }
          idAddressStr={
            idAddress
              ? [
                  idAddress.streetNumber ?? '',
                  idAddress.street ?? '',
                  idAddress.city ?? '',
                  idAddress.state ?? '',
                  idAddress.country ?? ''
                ]
                  .join(' ')
                  .trim()
              : ''
          }
          ipAddressStr={
            ipAddress
              ? [
                  ipAddress.city ?? '',
                  ipAddress.state ?? '',
                  ipAddress.country ?? ''
                ]
                  .join(' ')
                  .trim()
              : ''
          }
          ipGeocode={
            ipAddress?.location
              ? {
                  lat: ipAddress.location.latitude,
                  lng: ipAddress.location.longitude
                }
              : undefined
          }
        />
      )}
      {!!confidences && (
        <ScoresDropdown
          barcodeMatch={roundNumber(confidences.barcodeMatch) ?? undefined}
          birthDateMatch={roundNumber(confidences.birthDateMatch) ?? undefined}
          expired={roundNumber(confidences.idExpired) ?? undefined}
          faceMatch={roundNumber(confidences.faceMatch) ?? undefined}
          id={roundNumber(confidences.id) ?? undefined}
          nameMatch={roundNumber(confidences.nameMatch) ?? undefined}
          selfie={roundNumber(confidences.selfie) ?? undefined}
        />
      )}
      {!!confidences && (
        <ReviewDropdowns
          jobConfidences={confidences}
          jobErrors={jobErrors}
          jobReview={jobReview}
          jobSid={jobSid}
          setJobReviewSuccess={setJobReviewSuccess}
        />
      )}
      {filesDropdownVisible && <FilesDropdown jobStatus={jobStatus} />}
      {debugDropdownVisible && (
        <DebugDropdowns jobDebugResults={jobDebugResults} />
      )}
    </>
  );
};
