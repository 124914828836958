import { Box, Text } from '@vouched-id/vault';
import React, { FC, useMemo } from 'react';
import { JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { SingleSpanTable } from '../SingleSpanTable';
import { Tooltip } from '../Tooltip';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { DLVCaption } from './DLVCaption';
import { DLVSubmittedData, getDlvMatch } from './DLVUtils';
import { VerifyDlvResponse } from 'models/jobs/jobs';

interface DLVSuccessfulResultProps {
  readonly dlvResult: VerifyDlvResponse;
  readonly jobStatus: JobStatus;
  readonly dlvSubmittedData: DLVSubmittedData;
}

export const DLVSuccessfulResult: FC<DLVSuccessfulResultProps> = ({
  dlvResult,
  jobStatus,
  dlvSubmittedData
}) => {
  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const licenseNumberIsMatch = getDlvMatch(dlvResult, 'licenseNumber');
      const lastNameIsMatch = getDlvMatch(dlvResult, 'lastName');
      const birthDateIsMatch = getDlvMatch(dlvResult, 'birthDate');
      const expirationDateIsMatch = getDlvMatch(dlvResult, 'expirationDate');
      const stateIsMatch = getDlvMatch(dlvResult, 'state');

      const dropdownItemsWithoutIcons: BooleanDropdownItem[] = [
        {
          text: 'License Number',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!licenseNumberIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: !licenseNumberIsMatch,
            hasWarning: false
          },
          submittedData: dlvSubmittedData.id
        },
        {
          text: 'Last Name',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!lastNameIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: !lastNameIsMatch,
            hasWarning: false
          },
          submittedData: dlvSubmittedData.lastName
        },
        {
          text: 'Birth Date',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!birthDateIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: !birthDateIsMatch,
            hasWarning: false
          },
          submittedData: dlvSubmittedData.birthDate
        },
        {
          text: 'Expiration Date',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: expirationDateIsMatch,
          available: dlvResult.source === 'governmental',
          hasErrorOrWarning: {
            hasError: expirationDateIsMatch === false,
            hasWarning: false
          },
          submittedData: dlvSubmittedData.expirationDate
        }
      ];

      if (dlvResult.source === 'governmental') {
        dropdownItemsWithoutIcons.push({
          text: 'State',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!stateIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: !stateIsMatch,
            hasWarning: false
          },
          submittedData: dlvSubmittedData.state
        });
      }

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [dlvResult, dlvSubmittedData]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="DLV"
    >
      <Box>
        <DLVCaption />
        <SingleSpanTable
          items={dropdownItems.map((dropdownItem) => {
            return {
              label: dropdownItem.text,
              value: dropdownItem.submittedData,
              statusIcon: dropdownItem.statusIcon
            };
          })}
          numColumns={3}
        />
        <Box display="flex" style={{ gap: '20px', alignItems: 'center' }}>
          <Text>Source:</Text>
          <Text style={{ fontWeight: '600' }}>
            {dlvResult.source === 'nonGovernmental'
              ? 'non-governmental'
              : dlvResult.source}
          </Text>
          <Tooltip>
            Indicates if the response is from a government issuing authority or
            a proprietary data source.
          </Tooltip>
        </Box>
      </Box>
    </Dropdown>
  );
};
