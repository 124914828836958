import { Box, Text } from '@vouched-id/vault';
import React from 'react';
import { ImageSlider } from '../jobs/image-slider';
import { Image } from '../jobs/image-slider/smart-image';
import { dropdownWrapperStyle } from './dropdowns/utils';
import { JobImageType } from 'models/jobs/jobs';

interface JobImagesProps {
  readonly frontIdImages: Image[];
  readonly backIdImages: Image[];
  readonly userImages: Image[];
  readonly isReverification: boolean;
}

export const JobImages: React.FC<JobImagesProps> = ({
  frontIdImages,
  backIdImages,
  userImages,
  isReverification
}) => {
  const hasFrontIdImages = frontIdImages.length > 0;
  const hasBackIdImages = backIdImages.length > 0;
  const hasUserImages = userImages.length > 0;

  if (!hasFrontIdImages && !hasBackIdImages && !hasUserImages) {
    return <Text>No images have been submitted yet for this job.</Text>;
  }

  return (
    <Box
      display="grid"
      marginBottom="spacing.large"
      style={{
        ...dropdownWrapperStyle,
        gridTemplateColumns: 'repeat(3, calc(100% / 3))'
      }}
    >
      {hasFrontIdImages && (
        <ImageSlider
          imageType={JobImageType.IDFront}
          images={frontIdImages}
          selectedImage={0}
          type={isReverification ? 'Original' : ''}
        />
      )}
      {hasBackIdImages && (
        <ImageSlider
          imageType={JobImageType.IDBack}
          images={backIdImages}
          selectedImage={0}
          type={isReverification ? 'Reverification' : ''}
        />
      )}
      {hasUserImages && (
        <ImageSlider
          imageType={JobImageType.Selfie}
          images={userImages}
          selectedImage={0}
        />
      )}
    </Box>
  );
};
