import React, { FC } from 'react';
import { SingleSpanTable } from '../SingleSpanTable';
import { DobRequestData } from 'components/account/jobs/job-modal/job-modal-tabs/components/dob/dob_model';

interface DOBRequestDataTableProps {
  readonly requestData: DobRequestData;
}

export const DOBRequestDataTable: FC<DOBRequestDataTableProps> = ({
  requestData
}) => {
  return (
    <SingleSpanTable
      items={[
        {
          label: 'First Name',
          value: requestData.firstName
        },
        {
          label: 'Last Name',
          value: requestData.lastName
        },
        {
          label: 'Date of Birth',
          value: requestData.dob
        },
        {
          label: 'Email',
          value: requestData.email
        },
        {
          label: 'Phone',
          value: requestData.phone
        },
        {
          label: 'Street Address',
          value: requestData.address?.streetAddress
        },
        {
          label: 'Unit',
          value: requestData.address?.unit
        },
        {
          label: 'City',
          value: requestData.address?.city
        },
        {
          label: 'State',
          value: requestData.address?.state
        },
        {
          label: 'Postal Code',
          value: requestData.address?.postalCode
        },
        {
          label: 'Country',
          value: requestData.address?.country
        }
      ]}
      numColumns={2}
    />
  );
};
