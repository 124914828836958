import React, { FC } from 'react';
import { MultiSpanTable, MultiSpanTableEntry } from '../MultiSpanTable';
import { humanIdType } from 'utils/jobs';

interface IDVDetailsTableProps {
  readonly extractedData: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    birthDate?: string;
    type?: string;
    id?: string;
    country?: string;
    state?: string;
    expireDate?: string;
    issueDate?: string;
  };
  readonly submittedData: {
    firstName?: string;
    lastName?: string;
    dob?: string;
    email?: string;
    phone?: string;
  };
  readonly deviceData: {
    referer?: string;
    userAgent?: string;
    theme?: string;
    client?: string;
    ipAddress?: string;
  };
}

export const IDVDetailsTable: FC<IDVDetailsTableProps> = ({
  extractedData,
  submittedData,
  deviceData
}) => {
  const entries: MultiSpanTableEntry[] = [
    {
      title: 'Extracted',
      items: [
        {
          label: 'First Name',
          value: extractedData.firstName
        },
        {
          label: 'Middle Name',
          value: extractedData.middleName
        },
        {
          label: 'Last Name',
          value: extractedData.lastName
        },
        {
          label: 'Birth Date',
          value: extractedData.birthDate
        },
        {
          label: 'Type',
          value: humanIdType(extractedData.type) ?? undefined
        },
        {
          label: 'ID Number',
          value: extractedData.id
        },
        {
          label: 'Country',
          value: extractedData.country
        },
        {
          label: 'State',
          value: extractedData.state
        },
        {
          label: 'Expiration Date',
          value: extractedData.expireDate
        },
        {
          label: 'Issue Date',
          value: extractedData.issueDate
        }
      ]
    },
    {
      title: 'Submitted',
      items: [
        {
          label: 'First Name',
          value: submittedData.firstName
        },
        {
          label: 'Last Name',
          value: submittedData.lastName
        },
        {
          label: 'Birth Date',
          value: submittedData.dob
        },
        {
          label: 'Email',
          value: submittedData.email
        },
        {
          label: 'Phone',
          value: submittedData.phone
        }
      ]
    },
    {
      title: 'Device',
      items: [
        {
          label: 'Referer',
          value: deviceData.referer
        },
        {
          label: 'User Agent',
          value: deviceData.userAgent
        },
        {
          label: 'Theme',
          value: deviceData.theme
        },
        {
          label: 'Client',
          value: deviceData.client
        },
        {
          label: 'IP Address',
          value: deviceData.ipAddress
        }
      ]
    }
  ];

  return <MultiSpanTable entries={entries} header="Details" />;
};
